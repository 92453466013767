//App.js 
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.css';
import './App.css';
import { formatDistanceToNow } from 'date-fns';
import * as THREE from 'three';

function App() {
    const [fps, setFps] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [weatherData, setWeatherData] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const popupRef = useRef(null);
    const [recentCities, setRecentCities] = useState(() => {
        const saved = localStorage.getItem('recentCities');
        return saved ? JSON.parse(saved) : [];
    });

    const canvasRef = useRef(null);
    const glRef = useRef(null);
    const programRef = useRef(null);
    const uniformLocationsRef = useRef(null);
    const lastFrameTimeRef = useRef(performance.now());
    const frameCountRef = useRef(0);
    const targetFPSRef = useRef(120);
    const lastRenderTimeRef = useRef(performance.now());
    const animationRef = useRef(null);

    const API_KEY = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;

    const [windDirection, setWindDirection] = useState(0);
    const [humidity, setHumidity] = useState(0);
    const [pressure, setPressure] = useState(0);

    const starMeshesRef = useRef([]);

    const displayStars = useCallback((starData) => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(renderer.domElement);

        starMeshesRef.current = starData.map(star => {
            const geometry = new THREE.SphereGeometry(0.1, 32, 32);
            const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            const starMesh = new THREE.Mesh(geometry, material);
            starMesh.position.set(star.x, star.y, star.z);
            scene.add(starMesh);
            return starMesh;
        });

        camera.position.z = 5;

        function animate() {
            requestAnimationFrame(animate);
            scene.rotation.y += 0.0001;
            renderer.render(scene, camera);
        }
        animate();
    }, []);

    const fetchStarData = useCallback(async (latitude, longitude) => {
        try {
            const now = new Date();
            const url = `https://api.starsapi.com/stars?lat=${latitude}&lon=${longitude}&time=${now.toISOString()}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Star data not available');
            }
            const data = await response.json();
            displayStars(data);
        } catch (err) {
            console.error('Error fetching star data:', err.message);
        }
    }, [displayStars]);

    const fetchWeatherData = useCallback(async (city) => {
        try {
            const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${API_KEY}&units=metric`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Weather data not available');
            }
            const data = await response.json();

            console.log('Fetched Weather Data:', data);
            setWeatherData(data);
            setWindDirection(data.wind.deg);
            setHumidity(data.main.humidity);
            setPressure(data.main.pressure);

            // Fetch star data based on the city's coordinates
            fetchStarData(data.coord.lat, data.coord.lon);
        } catch (err) {
            console.error('Error fetching weather data:', err.message);
            alert(`Error fetching weather data for ${city}: ${err.message}`);
        }
    }, [API_KEY, fetchStarData]);

    useEffect(() => {
        if (selectedCity) {
            fetchWeatherData(selectedCity);
        }
    }, [selectedCity, fetchWeatherData]);

    useEffect(() => {
        if (selectedCity) {
            const now = new Date().toISOString();
            setRecentCities(prevCities => {
                const updatedCities = [
                    { name: selectedCity, timestamp: now },
                    ...prevCities.filter(city => city.name !== selectedCity).slice(0, 2)
                ];
                localStorage.setItem('recentCities', JSON.stringify(updatedCities));
                return updatedCities;
            });
        }
    }, [selectedCity]);

    useEffect(() => {
        const initializeWebGL = () => {
            const canvas = canvasRef.current;
            const gl = canvas.getContext('webgl');
            if (!gl) {
                console.error('Unable to initialize WebGL. Your browser may not support it.');
                return;
            }
            glRef.current = gl;

            const program = initShaderProgram(gl, vertexShaderSource, fragmentShaderSource);
            if (!program) {
                console.error('Failed to initialize shader program.');
                return;
            }
            programRef.current = program;

            gl.useProgram(program);

            const positionBuffer = initPositionBuffer(gl);
            const textureCoordBuffer = initTextureCoordBuffer(gl);

            const positionAttributeLocation = gl.getAttribLocation(program, 'aVertexPosition');
            gl.enableVertexAttribArray(positionAttributeLocation);
            gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
            gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);

            const textureCoordAttributeLocation = gl.getAttribLocation(program, 'aTextureCoord');
            gl.enableVertexAttribArray(textureCoordAttributeLocation);
            gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
            gl.vertexAttribPointer(textureCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);

            uniformLocationsRef.current = {
                time: gl.getUniformLocation(program, 'u_time'),
                temperature: gl.getUniformLocation(program, 'u_temperature'),
                cloudiness: gl.getUniformLocation(program, 'u_cloudiness'),
                windSpeed: gl.getUniformLocation(program, 'u_windSpeed'),
                rainIntensity: gl.getUniformLocation(program, 'u_rainIntensity'),
                weatherCondition: gl.getUniformLocation(program, 'u_weatherCondition'),
                timeOfDay: gl.getUniformLocation(program, 'u_timeOfDay'),
                resolution: gl.getUniformLocation(program, 'u_resolution'),
                cloudAltitude: gl.getUniformLocation(program, 'u_cloudAltitude'),
                cloudCoverage: gl.getUniformLocation(program, 'u_cloudCoverage'),
                cloudPrecipitation: gl.getUniformLocation(program, 'u_cloudPrecipitation'),
                windDirection: gl.getUniformLocation(program, 'u_windDirection'),
                humidity: gl.getUniformLocation(program, 'u_humidity'),
                pressure: gl.getUniformLocation(program, 'u_pressure'),
                latitude: gl.getUniformLocation(program, 'u_latitude'),
                longitude: gl.getUniformLocation(program, 'u_longitude'),
            };

            console.log('Uniform locations:', uniformLocationsRef.current);
        };

        initializeWebGL();
    }, []);

    const [canvasOpacity, setCanvasOpacity] = useState(0);

    useEffect(() => {
        const render = () => {
            const now = performance.now();
            const deltaTime = now - lastRenderTimeRef.current;

            if (deltaTime >= 1000 / targetFPSRef.current) {
                lastRenderTimeRef.current = now;

                const gl = glRef.current;
                if (gl && programRef.current && weatherData) {
                    const canvas = canvasRef.current;
                    gl.viewport(0, 0, canvas.width, canvas.height);

                    gl.clearColor(0.0, 0.0, 0.0, 1.0);
                    gl.clear(gl.COLOR_BUFFER_BIT);

                    const localTimeInHours = (new Date(weatherData.dt * 1000)).getUTCHours() + (weatherData.timezone / 3600);
                    const timeOfDay = localTimeInHours % 24;

                    const cloudType = CLOUD_TYPES.find(type => type.type === "Cumulus");

                    // Log the values being passed to the shader
                    console.log('Weather Data:', weatherData);
                    console.log('Cloud Type:', cloudType);
                    console.log('Time of Day:', timeOfDay);

                    gl.uniform1f(uniformLocationsRef.current.time, now * 0.001);
                    gl.uniform1f(uniformLocationsRef.current.temperature, weatherData.main.temp);
                    gl.uniform1f(uniformLocationsRef.current.cloudiness, weatherData.clouds.all);
                    gl.uniform1f(uniformLocationsRef.current.windSpeed, weatherData.wind.speed);
                    gl.uniform1f(uniformLocationsRef.current.rainIntensity, weatherData.rain ? weatherData.rain['1h'] : 0);
                    gl.uniform1i(uniformLocationsRef.current.weatherCondition, weatherData.weather[0].id);
                    gl.uniform1f(uniformLocationsRef.current.timeOfDay, timeOfDay);
                    gl.uniform2f(uniformLocationsRef.current.resolution, canvas.width, canvas.height);
                    gl.uniform1f(uniformLocationsRef.current.cloudAltitude, cloudType.altitude);
                    gl.uniform1f(uniformLocationsRef.current.cloudCoverage, cloudType.coverage);
                    gl.uniform1f(uniformLocationsRef.current.cloudPrecipitation, cloudType.precipitation);
                    gl.uniform1f(uniformLocationsRef.current.windDirection, windDirection * Math.PI / 180);
                    gl.uniform1f(uniformLocationsRef.current.humidity, humidity);
                    gl.uniform1f(uniformLocationsRef.current.pressure, pressure);
                    gl.uniform1f(uniformLocationsRef.current.latitude, weatherData.coord.lat);
                    gl.uniform1f(uniformLocationsRef.current.longitude, weatherData.coord.lon);

                    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

                    if (gl.getError() !== gl.NO_ERROR) {
                        console.error('WebGL Error during rendering');
                    }
                }

                frameCountRef.current++;
                const timeSinceLastFPSUpdate = now - lastFrameTimeRef.current;
                if (timeSinceLastFPSUpdate >= 1000) {
                    setFps(Math.round((frameCountRef.current * 1000) / timeSinceLastFPSUpdate));
                    frameCountRef.current = 0;
                    lastFrameTimeRef.current = now;
                }
            }

            animationRef.current = requestAnimationFrame(render);
        };

        animationRef.current = requestAnimationFrame(render);

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [weatherData, windDirection, humidity, pressure]);

    useEffect(() => {
        if (selectedCity) {
            setCanvasOpacity(0); // Reset opacity
            const fadeIn = () => {
                setCanvasOpacity(prev => Math.min(prev + 0.1, 1)); // Increase increment value
            };
            const interval = setInterval(fadeIn, 25); // Decrease interval time
            return () => clearInterval(interval);
        }
    }, [selectedCity]);

    const countries = [
        { value: 'us', label: 'United States', continent: 'North America' },
        { value: 'uk', label: 'United Kingdom', continent: 'Europe' },
        { value: 'ca', label: 'Canada', continent: 'North America' },
        { value: 'tw', label: 'Taiwan', continent: 'Asia' },
        { value: 'fr', label: 'France', continent: 'Europe' },
        { value: 'de', label: 'Germany', continent: 'Europe' },
        { value: 'jp', label: 'Japan', continent: 'Asia' },
        { value: 'au', label: 'Australia', continent: 'Oceania' },
        { value: 'in', label: 'India', continent: 'Asia' },
        { value: 'br', label: 'Brazil', continent: 'South America' },
        { value: 'cn', label: 'China', continent: 'Asia' },
        { value: 'ru', label: 'Russia', continent: 'Europe' },
        { value: 'nl', label: 'Netherlands', continent: 'Europe' }, // Added Netherlands
    ];

    const cities = {
        us: [
            { value: 'New York', label: 'New York' },
            { value: 'Los Angeles', label: 'Los Angeles' },
            { value: 'Chicago', label: 'Chicago' },
        ],
        uk: [
            { value: 'London', label: 'London' },
            { value: 'Manchester', label: 'Manchester' },
            { value: 'Birmingham', label: 'Birmingham' },
        ],
        ca: [
            { value: 'Toronto', label: 'Toronto' },
            { value: 'Vancouver', label: 'Vancouver' },
            { value: 'Montreal', label: 'Montreal' },
        ],
        tw: [
            { value: 'Taipei', label: 'Taipei' },
            { value: 'Taichung', label: 'Taichung' },
            { value: 'Kaohsiung', label: 'Kaohsiung' },
            { value: 'Tainan', label: 'Tainan' }  // Added Tainan
        ],
        fr: [
            { value: 'Paris', label: 'Paris' },
            { value: 'Marseille', label: 'Marseille' },
            { value: 'Lyon', label: 'Lyon' },
        ],
        de: [
            { value: 'Berlin', label: 'Berlin' },
            { value: 'Munich', label: 'Munich' },
            { value: 'Hamburg', label: 'Hamburg' },
        ],
        jp: [
            { value: 'Tokyo', label: 'Tokyo' },
            { value: 'Osaka', label: 'Osaka' },
            { value: 'Kyoto', label: 'Kyoto' },
        ],
        au: [
            { value: 'Sydney', label: 'Sydney' },
            { value: 'Melbourne', label: 'Melbourne' },
            { value: 'Brisbane', label: 'Brisbane' },
        ],
        in: [
            { value: 'Mumbai', label: 'Mumbai' },
            { value: 'Delhi', label: 'Delhi' },
            { value: 'Bangalore', label: 'Bangalore' },
        ],
        br: [
            { value: 'Sao Paulo', label: 'Sao Paulo' },
            { value: 'Rio de Janeiro', label: 'Rio de Janeiro' },
            { value: 'Brasilia', label: 'Brasilia' },
        ],
        cn: [
            { value: 'Beijing', label: 'Beijing' },
            { value: 'Shanghai', label: 'Shanghai' },
            { value: 'Guangzhou', label: 'Guangzhou' },
        ],
        ru: [
            { value: 'Moscow', label: 'Moscow' },
            { value: 'Saint Petersburg', label: 'Saint Petersburg' },
            { value: 'Novosibirsk', label: 'Novosibirsk' },
        ],
        nl: [ // Added cities for Netherlands
            { value: 'Amsterdam', label: 'Amsterdam' },
            { value: 'Rotterdam', label: 'Rotterdam' },
            { value: 'The Hague', label: 'The Hague' },
        ],
    };

    // Group countries by continent and sort them alphabetically
    const groupedCountries = countries.reduce((acc, country) => {
        if (!acc[country.continent]) {
            acc[country.continent] = [];
        }
        acc[country.continent].push(country);
        acc[country.continent].sort((a, b) => a.label.localeCompare(b.label));
        return acc;
    }, {});

    // Sort the continents alphabetically
    const sortedContinents = Object.keys(groupedCountries).sort((a, b) => a.localeCompare(b));

    const togglePopup = (event) => {
        event.stopPropagation(); // Prevent the click event from propagating to the document
        setPopupVisible((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopupVisible(false);
        }
    };

    useEffect(() => {
        if (popupVisible) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [popupVisible]);

    const handleDoubleClick = () => {
        if (!document.fullscreenElement) {
            const canvas = canvasRef.current;
            if (canvas.requestFullscreen) {
                canvas.requestFullscreen();
            } else if (canvas.mozRequestFullScreen) { // Firefox
                canvas.mozRequestFullScreen();
            } else if (canvas.webkitRequestFullscreen) { // Chrome, Safari and Opera
                canvas.webkitRequestFullscreen();
            } else if (canvas.msRequestFullscreen) { // IE/Edge
                canvas.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }
    };

    const handleFullScreenToggle = () => {
        if (selectedCity) { // Only allow full screen if a city is selected
            if (!document.fullscreenElement) {
                const canvas = canvasRef.current;
                if (canvas.requestFullscreen) {
                    canvas.requestFullscreen();
                } else if (canvas.mozRequestFullScreen) { // Firefox
                    canvas.mozRequestFullScreen();
                } else if (canvas.webkitRequestFullscreen) { // Chrome, Safari and Opera
                    canvas.webkitRequestFullscreen();
                } else if (canvas.msRequestFullscreen) { // IE/Edge
                    canvas.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { // Firefox
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { // IE/Edge
                    document.msExitFullscreen();
                }
            }
        }
    };

    useEffect(() => {
        const canvas = document.querySelector('canvas');
        const header = document.querySelector('.header');

        const toggleHeader = () => {
            if (selectedCity) { // Only toggle if a city is selected
                header.classList.toggle('collapsed');
            }
        };

        canvas.addEventListener('click', toggleHeader);
        // Removed touchstart event listener
        // canvas.addEventListener('touchstart', toggleHeader);

        return () => {
            canvas.removeEventListener('click', toggleHeader);
            // Removed touchstart event listener
            // canvas.removeEventListener('touchstart', toggleHeader);
        };
    }, [selectedCity]);

    const handleRecentCityClick = async (cityData) => {
        // Find the country for this city
        let cityCountry = '';
        for (let [countryCode, countryCities] of Object.entries(cities)) {
            if (countryCities.some(c => c.value === cityData.name)) {
                cityCountry = countryCode;
                break;
            }
        }

        if (cityCountry) {
            setSelectedCountry(cityCountry);
            // Wait for the state to update
            await new Promise(resolve => setTimeout(resolve, 0));
            setSelectedCity(cityData.name);
            
            // Update the timestamp for the clicked city
            const now = new Date().toISOString();
            const updatedCities = [
                { name: cityData.name, timestamp: now },
                ...recentCities.filter(city => city.name !== cityData.name)
            ];
            setRecentCities(updatedCities);
            localStorage.setItem('recentCities', JSON.stringify(updatedCities));

            // Hide the intro box and recent cities
            setShowIntroButtons(false); // Ensure the intro box is hidden
            setShowIntroButtons(false); // Ensure the recent city buttons are hidden

            // Fetch weather data or perform any other necessary actions
            fetchWeatherData(cityData.name);
        } else {
            console.error(`Country not found for city: ${cityData.name}`);
        }
    };

    // Update the logStarPositions function
    function logStarPositions() {
        starMeshesRef.current.forEach((starMesh, index) => {
            console.log(`Star ${index}: x=${starMesh.position.x}, y=${starMesh.position.y}, z=${starMesh.position.z}`);
        });
    }

    // Expose the function to the console
    useEffect(() => {
        window.stars = logStarPositions;
        return () => {
            delete window.stars;
        };
    }, []);

    const getLocalTime = (timezone) => {
        const utcTime = new Date().getTime() + (new Date().getTimezoneOffset() * 60000);
        const localTime = new Date(utcTime + timezone * 1000);
        return localTime.toLocaleTimeString();
    };

    const [showIntroButtons, setShowIntroButtons] = useState(true);
    const [showCitySelect, setShowCitySelect] = useState(false);

    const handleIntroCountrySelect = (countryValue) => {
        setSelectedCountry(countryValue);
        setShowCitySelect(true);
    };

    const handleIntroCitySelect = (cityValue) => {
        setSelectedCity(cityValue);
        setShowIntroButtons(false);
        setShowIntroButtons(false); // Hide the intro box after city selection
    };

    useEffect(() => {
        const introButtons = document.querySelector('.intro-buttons');
        if (introButtons) {
            if (!showCitySelect) {
                introButtons.classList.add('single-select');
            } else {
                introButtons.classList.remove('single-select');
            }
        }
    }, [showCitySelect]);

    const hasSelectedCityBefore = recentCities.length > 0;

    return (
        <div className="App">
            {selectedCity && (
                <div className="header">
                    <div className="title-container">
                        <h1 className="title">
                            <a href={window.location.href}>Horizon</a>
                        </h1>
                    </div>
                    <div className="controls">
                        {!showIntroButtons && (
                            <>
                                <select
                                    value={selectedCountry}
                                    onChange={(e) => setSelectedCountry(e.target.value)}
                                >
                                    <option value="">Select a country</option>
                                    {sortedContinents.map(continent => (
                                        <optgroup key={continent} label={continent}>
                                            {groupedCountries[continent].map(country => (
                                                <option key={country.value} value={country.value}>{country.label}</option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>

                                {selectedCountry && (
                                    <select
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.target.value)}
                                    >
                                        <option value="">Select a city</option>
                                        {cities[selectedCountry].map((city) => (
                                            <option key={city.value} value={city.value}>{city.label}</option>
                                        ))}
                                    </select>
                                )}
                            </>
                        )}
                        {weatherData && (
                            <>
                                <svg
                                    className="weather-icon"
                                    onClick={togglePopup}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx="12" cy="12" r="5"></circle>
                                    <line x1="12" y1="1" x2="12" y2="3"></line>
                                    <line x1="12" y1="21" x2="12" y2="23"></line>
                                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                    <line x1="1" y1="12" x2="3" y2="12"></line>
                                    <line x1="21" y1="12" x2="23" y2="12"></line>
                                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                                </svg>
                                <svg
                                    className="fullscreen-icon"
                                    onClick={handleFullScreenToggle}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M8 3H5a2 2 0 0 0-2 2v3"></path>
                                    <path d="M16 3h3a2 2 0 0 1 2 2v3"></path>
                                    <path d="M8 21H5a2 2 0 0 1-2-2v-3"></path>
                                    <path d="M16 21h3a2 2 0 0 0 2-2v-3"></path>
                                </svg>
                            </>
                        )}
                    </div>
                    <p className="fps">
                        <span className="fps-full">Current </span>
                        FPS: {fps}
                    </p>
                </div>
            )}

            <div className="canvas-container" onDoubleClick={handleDoubleClick}>
                <canvas
                    ref={canvasRef}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    style={{ opacity: canvasOpacity, transition: 'opacity 0.25s ease-in-out' }}
                />
                {showIntroButtons && (
                    <div className="intro-container">
                        <div className="icon-title-container">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="horizon-icon"
                                style={{ width: '36px', height: '36px' }} // Set to 36px
                            >
                                <path d="M12 2 A10 10 0 0 1 22 12" />
                                <path d="M12 22 A10 10 0 0 1 2 12" />
                                <line x1="7" y1="12" x2="17" y2="12" />
                                <circle cx="17" cy="8" r="1" fill="currentColor" /> {/* Solid circle */}
                            </svg>
                            <h1 className="canvas-title">Horizon</h1>
                        </div>
                        {!hasSelectedCityBefore && !selectedCity && (
                            <div className="intro-text" style={{ marginTop: '1.5rem' }}>
                                <h2>Welcome to Horizon!</h2>
                                <p>Select a country and city to watch a real-time, procedurally generated sky unfold, evolving dynamically with changing weather patterns.</p>
                            </div>
                        )}
                        <div className="intro-content">
                            <div className="intro-button-container">
                                {hasSelectedCityBefore && (
                                    <div className="recent-cities-container">
                                        <h3 className="recent-cities-heading">Recent cities</h3>
                                        <div className="recent-cities">
                                            {recentCities.map((city, index) => {
                                                const country = countries.find(c => cities[c.value].some(cityObj => cityObj.value === city.name));
                                                return (
                                                    <button 
                                                        key={index} 
                                                        className="recent-city-btn"
                                                        onClick={() => handleRecentCityClick(city)}
                                                    >
                                                        <span className="country">{country ? country.label : ''}</span>
                                                        {city.name}
                                                        <span className="time-ago">
                                                            {formatDistanceToNow(new Date(city.timestamp), { addSuffix: true })}
                                                        </span>
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                                <div className="intro-buttons-container">
                                    <h3 className="select-location-heading">Select a location</h3>
                                    <div className={`intro-buttons ${showCitySelect ? 'country-selected' : ''}`}>
                                        <div className="select-wrapper">
                                            <select
                                                className={`intro-select ${!selectedCountry ? 'selected' : ''}`}
                                                value={selectedCountry}
                                                onChange={(e) => handleIntroCountrySelect(e.target.value)}
                                            >
                                                <option value="">Select a country</option>
                                                {sortedContinents.map(continent => (
                                                    <optgroup key={continent} label={continent}>
                                                        {groupedCountries[continent].map(country => (
                                                            <option key={country.value} value={country.value}>{country.label}</option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </select>
                                            <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </div>
                                        {showCitySelect && (
                                            <div className="select-wrapper">
                                                <select
                                                    className={`intro-select ${!selectedCity ? 'selected' : ''}`}
                                                    value={selectedCity}
                                                    onChange={(e) => handleIntroCitySelect(e.target.value)}
                                                >
                                                    <option value="">Select a city</option>
                                                    {cities[selectedCountry].map((city) => (
                                                        <option key={city.value} value={city.value}>{city.label}</option>
                                                    ))}
                                                </select>
                                                <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div ref={popupRef} className={`popup ${popupVisible ? 'visible' : ''}`}>
                {weatherData && (
                    <>
                        <h3>{weatherData.name}</h3>
                        <p>Local Time: {getLocalTime(weatherData.timezone)}</p>
                        <p>{weatherData.weather[0].description}</p>
                        <p>Temperature: {weatherData.main.temp}°C</p>
                        <p>Cloudiness: {weatherData.clouds.all}%</p>
                        <p>Wind Speed: {weatherData.wind.speed} m/s</p>
                        <p>Rain (last 1h): {weatherData.rain ? weatherData.rain['1h'] : 0} mm</p>
                    </>
                )}
            </div>
        </div>
    );
}

function initShaderProgram(gl, vsSource, fsSource) {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);
    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        console.error('Unable to initialize the shader program:', gl.getProgramInfoLog(shaderProgram));
        return null;
    }

    return shaderProgram;
}

function loadShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('An error occurred compiling the shaders:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

function initPositionBuffer(gl) {
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = [
        -1.0,  1.0,
         1.0,  1.0,
        -1.0, -1.0,
         1.0, -1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
    return positionBuffer;
}

function initTextureCoordBuffer(gl) {
    const textureCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
    const textureCoordinates = [
        0.0,  0.0,
        1.0,  0.0,
        0.0,  1.0,
        1.0,  1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), gl.STATIC_DRAW);
    return textureCoordBuffer;
}

const fragmentShaderSource = `
    #define LYGIA_PI 3.14159265359

    precision highp float;
    varying highp vec2 vTextureCoord;
    uniform float u_time;
    uniform float u_temperature;
    uniform float u_cloudiness;
    uniform float u_windSpeed;
    uniform float u_rainIntensity;
    uniform int u_weatherCondition;
    uniform float u_timeOfDay;
    uniform vec2 u_resolution;
    uniform float u_cloudAltitude;
    uniform float u_cloudCoverage;
    uniform float u_cloudPrecipitation;
    uniform float u_windDirection;
    uniform float u_humidity;
    uniform float u_pressure;
    uniform float u_latitude;
    uniform float u_longitude;

    // Lygia's smoothstep function
    float lygia_smoothstep(float edge0, float edge1, float x) {
        float t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
        return t * t * (3.0 - 2.0 * t);
    }

    // Lygia's mix function
    vec3 lygia_mix(vec3 x, vec3 y, float a) {
        return x * (1.0 - a) + y * a;
    }

    // Improved noise function
    float hash(float n) { return fract(sin(n) * 1e4); }
    float hash(vec2 p) { return fract(1e4 * sin(17.0 * p.x + p.y * 0.1) * (0.1 + abs(sin(p.y * 13.0 + p.x)))); }

    float noise(vec2 x) {
        vec2 i = floor(x);
        vec2 f = fract(x);

        float a = hash(i);
        float b = hash(i + vec2(1.0, 0.0));
        float c = hash(i + vec2(0.0, 1.0));
        float d = hash(i + vec2(1.0, 1.0));

        vec2 u = f * f * (3.0 - 2.0 * f);
        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    float fbm(vec2 x) {
        float v = 0.0;
        float a = 0.5;
        vec2 shift = vec2(100.0);
        mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
        for (int i = 0; i < 5; ++i) {
            v += a * noise(x);
            x = rot * x * 2.0 + shift;
            a *= 0.5;
        }
        return v;
    }

    vec3 getSkyColor(float timeOfDay, float yCoord, float cloudiness, float rainIntensity) {
        vec3 dayColor = vec3(0.4, 0.7, 1.0);
        vec3 sunriseColor = vec3(1.0, 0.8, 0.5);
        vec3 sunsetColor = vec3(1.0, 0.7, 0.4);
        vec3 nightColor = vec3(0.0, 0.0, 0.1); // Dark blue night color
        vec3 greySky = vec3(0.5, 0.5, 0.5);

        vec3 skyColor;
        if (timeOfDay < 5.0 || timeOfDay > 19.0) {
            skyColor = nightColor;
        } else if (timeOfDay < 7.0) {
            skyColor = lygia_mix(nightColor, sunriseColor, (timeOfDay - 5.0) / 2.0);
        } else if (timeOfDay < 8.0) {
            skyColor = lygia_mix(sunriseColor, dayColor, (timeOfDay - 7.0));
        } else if (timeOfDay < 17.0) {
            skyColor = dayColor;
        } else if (timeOfDay < 18.0) {
            skyColor = lygia_mix(dayColor, sunsetColor, (timeOfDay - 17.0));
        } else {
            skyColor = lygia_mix(sunsetColor, nightColor, (timeOfDay - 18.0));
        }

        vec3 topColor = vec3(0.1, 0.2, 0.5);
        vec3 horizonColor = vec3(0.4, 0.7, 1.0);
        vec3 blendedColor = lygia_mix(topColor, horizonColor, yCoord);

        skyColor = lygia_mix(blendedColor, skyColor, yCoord);

        // Apply grey tint for cloudy and rainy conditions
        if (cloudiness > 0.75 || rainIntensity > 0.1) {
            skyColor = lygia_mix(skyColor, greySky, 0.5);
        } else if (cloudiness > 0.5) {
            skyColor = lygia_mix(skyColor, greySky, 0.3);
        }

        return skyColor;
    }

    vec3 getSunAndHalo(vec2 uv, float timeOfDay) {
        float dayLength = 14.0;
        float midday = 12.0;
        float sunriseTime = midday - dayLength / 2.0;
        float sunsetTime = midday + dayLength / 2.0;

        float normalizedTime = (timeOfDay - sunriseTime) / dayLength;
        normalizedTime = clamp(normalizedTime, 0.0, 1.0);

        float sunAngle = normalizedTime * LYGIA_PI;
        float sunHeight = sin(sunAngle);
        float sunHorizontal = cos(sunAngle);

        vec2 sunPos = vec2(sunHorizontal * 0.8 + 0.5, sunHeight * 0.5 + 0.1);
        
        float dist = distance(uv, sunPos);
        
        float baseSunSize = 0.02;
        float baseHaloSize = 0.1;
        float sunSize = mix(baseSunSize * 1.2, baseSunSize, sunHeight);
        float haloSize = mix(baseHaloSize * 1.2, baseHaloSize, sunHeight);
        
        float sunCore = 1.0 - lygia_smoothstep(0.0, sunSize, dist);
        vec3 sunColor = mix(vec3(1.0, 0.9, 0.6), vec3(1.0, 0.8, 0.4), lygia_smoothstep(0.0, sunSize, dist));
        
        float halo = 1.0 - lygia_smoothstep(sunSize, haloSize, dist);
        vec3 haloColor = mix(vec3(1.0, 0.9, 0.7), vec3(1.0, 0.8, 0.5), lygia_smoothstep(sunSize, haloSize, dist));
        
        vec3 sunAndHalo = sunColor * sunCore + haloColor * halo * 0.5;
        
        float sunVisibility = lygia_smoothstep(sunriseTime, sunriseTime + 1.0, timeOfDay) * 
                              lygia_smoothstep(sunsetTime, sunsetTime - 1.0, timeOfDay);
        return sunAndHalo * sunVisibility;
    }

    vec3 getMoonAndHalo(vec2 uv, float timeOfDay, float cloudiness, float rainIntensity) {
        float nightLength = 14.0;
        float midnight = 0.0;
        float moonriseTime = midnight - nightLength / 2.0;
        float moonsetTime = midnight + nightLength / 2.0;

        float normalizedTime = (timeOfDay - moonriseTime) / nightLength;
        normalizedTime = clamp(normalizedTime, 0.0, 1.0);

        float moonAngle = normalizedTime * LYGIA_PI;
        float moonHeight = sin(moonAngle);
        float moonHorizontal = cos(moonAngle);

        vec2 moonPos = vec2(moonHorizontal * 0.8 + 0.5, moonHeight * 0.5 + 0.1);
        
        float dist = distance(uv, moonPos);
        
        float baseMoonSize = 0.015; // Smaller base size for the moon
        float baseHaloSize = 0.05;  // Smaller and more subtle halo size
        float moonSize = mix(baseMoonSize * 1.2, baseMoonSize, moonHeight);
        float haloSize = mix(baseHaloSize * 1.2, baseHaloSize, moonHeight);
        
        float moonCore = 1.0 - lygia_smoothstep(0.0, moonSize, dist);
        vec3 moonColor = mix(vec3(0.9, 0.9, 1.0), vec3(0.8, 0.8, 1.0), lygia_smoothstep(0.0, moonSize, dist));
        
        float halo = 1.0 - lygia_smoothstep(moonSize, haloSize, dist);
        vec3 haloColor = mix(vec3(0.8, 0.8, 1.0), vec3(0.7, 0.7, 1.0), lygia_smoothstep(moonSize, haloSize, dist));
        
        // Adjust halo based on weather conditions
        float weatherEffect = 1.0 - (cloudiness / 100.0) * 0.5 - (rainIntensity / 100.0) * 0.2;
        haloColor *= weatherEffect;
        moonColor *= weatherEffect;
        
        vec3 moonAndHalo = moonColor * moonCore + haloColor * halo * 0.3; // More subtle halo effect
        
        float moonVisibility = lygia_smoothstep(moonriseTime, moonriseTime + 1.0, timeOfDay) * 
                               lygia_smoothstep(moonsetTime, moonsetTime - 1.0, timeOfDay);
        return moonAndHalo * moonVisibility;
    }

    float starIntensity(vec2 coordinates, float cloudiness, float humidity, float rainIntensity, float time) {
        float starSize = 0.03; // Updated star size
        float intensity = 0.0;
        
        // Adjust coordinates based on latitude and longitude
        vec2 adjustedCoords = coordinates;
        adjustedCoords.x += u_longitude / 360.0;
        adjustedCoords.y += u_latitude / 180.0;
        
        // Wrap coordinates
        adjustedCoords = fract(adjustedCoords);
        
        vec2 grid = floor(adjustedCoords * 20.0);
        float flicker = fract(sin(dot(grid, vec2(12.9898, 78.233))) * 43758.5453);
        if (flicker > 0.98) {
            float dist = distance(fract(adjustedCoords * 20.0), vec2(0.5));
            intensity = smoothstep(starSize, 0.0, dist) * flicker;
        }
        
        // Adjust star intensity based on weather conditions
        float weatherEffect = 1.0 - (cloudiness / 100.0) * 0.5 - (humidity / 100.0) * 0.3 - (rainIntensity / 100.0) * 0.2;
        
        // Add position-based and time-based flickering effect influenced by weather conditions
        float positionFactor = dot(grid, vec2(12.9898, 78.233));
        float flickerSpeed = 6.0 + (cloudiness + humidity) * 0.1 - rainIntensity * 0.1 + positionFactor * 0.1;
        float flickerEffect = 0.5 + 0.5 * sin(time * flickerSpeed + positionFactor);
        return intensity * weatherEffect * flickerEffect;
    }

    float cloudDensity(vec2 uv, float cloudiness, float humidity) {
        float fbm = fbm(uv * 3.0);
        float density = smoothstep(0.5, 0.8, fbm);
        density *= cloudiness;
        density = mix(density, 1.0, humidity / 200.0); // Increase density with humidity
        return density;
    }

    vec3 applyAtmosphericEffect(vec3 color, float pressure) {
        float normalPressure = 1013.25; // Standard sea-level pressure in hPa
        float pressureEffect = (pressure - normalPressure) / 100.0;
        vec3 tint = pressureEffect > 0.0 ? vec3(1.0, 0.95, 0.9) : vec3(0.9, 0.95, 1.0);
        return mix(color, color * tint, abs(pressureEffect) * 0.1);
    }

    void main(void) {
        vec2 st = vTextureCoord;
        st.x *= u_resolution.x / u_resolution.y;
        vec3 skyColor = getSkyColor(u_timeOfDay, st.y, u_cloudiness / 100.0, u_rainIntensity);
        vec3 sunAndHalo = getSunAndHalo(st, u_timeOfDay);
        vec3 moonAndHalo = getMoonAndHalo(st, u_timeOfDay, u_cloudiness, u_rainIntensity);

        skyColor += sunAndHalo * 0.2;
        skyColor += moonAndHalo * 0.2;

        // Create a separate coordinate for stars that doesn't move with wind
        vec2 starCoord = st;

        // Apply wind direction to cloud movement
        float windAngle = u_windDirection;
        vec2 windOffset = vec2(cos(windAngle), sin(windAngle)) * u_windSpeed * 0.01 * u_time;
        st += windOffset;

        float cloudiness = u_cloudiness / 100.0;
        float cloud = cloudDensity(st, cloudiness, u_humidity);
        vec3 cloudColor = mix(skyColor, vec3(1.08, 1.08, 1.08), cloud); // 20% brighter clouds

        // Apply atmospheric effect based on pressure
        cloudColor = applyAtmosphericEffect(cloudColor, u_pressure);

        // Add rain effect
        if (u_rainIntensity > 0.0) {
            vec2 rainUV = st * 40.0;
            rainUV.y += u_time * 0.1;
            float rainDrop = step(0.98, fract(sin(dot(floor(rainUV), vec2(12.9898, 78.233))) * 43758.5453));
            rainDrop *= smoothstep(0.0, 0.5, fract(rainUV.y));
            cloudColor = mix(cloudColor, vec3(0.6, 0.6, 0.6), rainDrop * u_rainIntensity * 0.05); // Darker rain effect
        }

        // Star field effect using starCoord (not affected by wind)
        vec3 finalColor = cloudColor;
        if (u_timeOfDay >= 18.0 || u_timeOfDay <= 6.0) { // Night time
            float stars = starIntensity(starCoord, u_cloudiness, u_humidity, u_rainIntensity, u_time);
            vec3 starColor = vec3(stars * 0.8, stars * 0.8, stars);
            finalColor += starColor * (1.0 - cloud); // Stars are less visible through clouds
        }

        // Darken the final color for night time
        if (u_timeOfDay < 5.0 || u_timeOfDay > 19.0) {
            finalColor *= 0.3; // Reduce brightness by 70%
        }

        gl_FragColor = vec4(finalColor, 1.0);
    }
`;

const vertexShaderSource = `
    attribute vec4 aVertexPosition;
    attribute vec2 aTextureCoord;
    varying highp vec2 vTextureCoord;
    void main(void) {
        gl_Position = aVertexPosition;
        vTextureCoord = aTextureCoord;
    }
`;

const CLOUD_TYPES = [
    { type: "Cumulus", altitude: 1500, coverage: 30, precipitation: 10 },
    { type: "Stratus", altitude: 500, coverage: 80, precipitation: 60 },
    { type: "Cirrus", altitude: 6000, coverage: 20, precipitation: 0 },
    // Add more cloud types as needed
];

export default App;